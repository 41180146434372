// Libraries
import type {AppProps} from 'next/app';
import {PersistGate} from 'redux-persist/integration/react';
import {useStore} from 'react-redux';
import 'reflect-metadata';

// Store
import {wrapper} from '@Src/store';

// Styles
import 'antd/dist/antd.css';
import '@Assets/css/globals.scss';

function MyApp({Component, pageProps}: AppProps) {
    const store = useStore();

    return (
        <PersistGate persistor={store.persistor} loading={null}>
            <Component {...pageProps} />
        </PersistGate>
    );
}

export default wrapper.withRedux(MyApp);

import {createSlice} from '@reduxjs/toolkit';
import {HYDRATE} from 'next-redux-wrapper';

export interface ThemeType {
    isDark: boolean
}

export interface RootState {
    isAuthenticated: boolean
    accessToken: string
    theme: ThemeType,
}

const initialState: RootState = {
    isAuthenticated: false,
    accessToken: '',
    theme: {
        isDark: false
    }
};

const Reducer = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setLoading: (state, action) => ({
            ...state
        })
    },
    extraReducers: {
        [HYDRATE]: (state) => ({
            ...state
        })
    }
});

export const {setLoading} = Reducer.actions;

export default Reducer.reducer;

// const reducer = (state = initialState, action: Record<string, any>) => {
//     switch (action.type) {
//         case actionTypes.UPDATE_AUTHENTICATE: {
//             return {...state, ...action.payload};
//         }
//         default:
//             return state;
//     }
// };
//
// export default reducer;

import {all, takeEvery} from 'redux-saga/effects';
import {setLoading} from '@Src/reducer';

function* handleAuthenticate(payload) {
    try {
        //
    } catch (err) {
        // yield put(failure(err))
    }
}

export default function* rootSaga () {
    yield all ([
        takeEvery(setLoading, handleAuthenticate)
    ]);
}

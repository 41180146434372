// Libraries
import {configureStore} from '@reduxjs/toolkit';
import {Store} from 'redux';
import createSagaMiddleware, {Task} from 'redux-saga';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {createWrapper} from 'next-redux-wrapper';

// Middleware
import rootSaga from './middleware';
import rootReducer from './reducer';

export interface SagaStore extends Store {
	sagaTask: Task;
}

let store;

export const makeStore = () => {
    const isServer = typeof window === 'undefined';

    if (isServer) {
        const sagaMiddleware = createSagaMiddleware();

        store = configureStore({
            reducer: rootReducer,
            middleware: () => [sagaMiddleware]
        });
        (store as SagaStore).sagaTask = sagaMiddleware.run(rootSaga);

        return store;
    }

    const persistConfig = {
        key: 'EOTY',
        // whitelist: [''],
        // blacklist: [''],
        storage
    };

    const persistedReducer = persistReducer(persistConfig, rootReducer);
    const sagaMiddleware = createSagaMiddleware();

    store = configureStore({
        reducer: persistedReducer,
        middleware: () => [sagaMiddleware]
    });

    store.persistor = persistStore(store);

    (store as SagaStore).sagaTask = sagaMiddleware.run(rootSaga);

    return store;
};

export {store};

export const wrapper = createWrapper<Store>(makeStore, {
    debug: false
});
